.boxInputImgs {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
    flex: 1 1 auto;
}

.boxInputImgs input[type="file"] {
    border: none;
    box-shadow: 0px 0px 1px 1px var(--slate-600);
    padding: 7px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}

.boxInputImgs input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    position: absolute;
    left: 20px;
}

.boxInputImgs input[type="file"]::before {
    content: 'Selecionar Arquivos';
    display: inline-block;
    background: var(--teal-600);
    padding: 5px 12px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: white;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 10px;
    transition: 0.3s;
}

.boxInputImgs input[type="file"]:hover::before {
    background: var(--teal-700);
}