#navMobile {
    display: none;
}

#menu li a {
    display: block;
}

#btn-mobile {
    display: none;
}

@media (max-width: 850px) {
    #navMobile {
        display: block;
    }

    #menu {
        display: block;
        position: absolute;
        width: 100%;
        top: 120px;
        right: 0px;
        background: var(--cyan-950);
        transition: 0.5s;
        z-index: 1000;
        height: 0px;
        visibility: hidden;
        overflow-y: hidden;
    }

    #navMobile.active #menu {
        height: calc(100vh - 120px);
        visibility: visible;
        overflow-y: auto;
    }

    .borda {
        border-bottom: 3px solid rgba(255, 255, 255, 0.05);
    }

    #menu li {
        border-bottom: 3px solid rgba(255, 255, 255, 0.05);
    }

    #menu li a {
        padding: 0.8rem 0.1rem;
        color: var(--cyan-50);
    }

    #btn-mobile {
        display: flex;
        padding: 0.2rem 0.3rem 0.2rem 0.1rem;
        font-size: 1rem;
        border: none;
        background: none;
        cursor: pointer;
        gap: 0.5rem;
        color: #e7e7e7;
    }

    #hamburger {
        border-top: 3px solid;
        width: 25px;
    }

    #hamburger::after,
    #hamburger::before {
        content: '';
        display: block;
        width: 25px;
        height: 3px;
        background: currentColor;
        margin-top: 6px;
        transition: 0.3s;
        position: relative;
    }

    #navMobile.active #hamburger {
        border-top-color: transparent;
    }

    #navMobile.active #hamburger::before {
        transform: rotate(135deg);
    }

    #navMobile.active #hamburger::after {
        transform: rotate(-135deg);
        top: -9px;
    }
}