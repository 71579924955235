#btnCadastrarDefault {
    margin: 0 auto;
    font-size: 1.2rem;
    width: 30%;
    min-width: 200px;
    padding: 8px;
    border-radius: 6px;
    color: aliceblue;
    background-color: var(--teal-700);
    border: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 10px;
    cursor: pointer;
}