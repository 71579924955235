@media (max-width:1200px) {

    /* HEADER */
    .containerHeaderItens {
        width: 90%;
    }

    /* NAVBAR */
    .navBar .lista li {
        font-size: 0.9rem;
    }

    .navBar .lista {
        width: 90%;
    }

    .dropdownMenu {
        top: 38px;
        width: 167px;
    }

    .dropdownMenu ul {
        width: 167px;
        left: 167px;
        height: 301px;
    }

    .dropdownMenu .listaPerife {
        top: -38px;
    }

    .dropdownMenu .listaRedes {
        top: -75px;
    }

    .dropdownMenu .listaComputadores {
        top: -113px;
    }

    /* HOME */
    .containerMainPaginaProdutos {
        width: 90%;
    }

    .containerProdutos {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .containerPaginaProduto {
        width: 90%;
    }

    .containerPadrao {
        width: 90%;
    }
}


@media (max-width:950px) {
    .navBar .lista li {
        font-size: 0.8rem;
    }

    .dropdownMenu {
        top: 35px;
        max-width: 153px;
    }

    .dropdownMenu ul {
        width: 153px;
        left: 153px;
    }

    .dropdownMenu .listaPerife {
        top: -38px;
    }

    .dropdownMenu .listaRedes {
        top: -75px;
    }

    .dropdownMenu .listaComputadores {
        top: -113px;
    }

    .dropdownMenu li a {
        font-size: 0.9rem;
    }
}

@media (max-width:850px) {
    .navBar .lista {
        display: none;
    }

    #btn-mobile {
        display: flex;
    }

    /* COMPRA */
    .containerMainProduto {
        padding: 1rem;
    }

    .containerMainProduto .containerProdutoInfo {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: space-between;
        margin-top: 1rem;
    }

    .containerProdutoInfo .containerDireitaProduto {
        display: grid;
        grid-template-columns: 1fr;
    }

    .containerDireitaProduto .btnsComprarAddCart button:nth-child(1) {
        padding: 0px 10px;
        justify-content: center;
        width: 100%;
    }

    .containerDireitaProduto .btnsComprarAddCart {
        width: 100%;
    }

    /* Pagina do produto */
    .containerEspecificacaoEDescricao {
        padding: 1rem;
    }

    .mainCarrinho {
        margin-bottom: 0rem;
    }

    .mainCarrinho .containerPrincipal {
        row-gap: 1rem;
    }
}

@media (max-width:600px) {

    /* FOOTER */
    .containerFooter section.caixas-informacoes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 90%;
    }

    .rodape {
        width: 90%;
    }

    .containerProdutoInfo .imgPrincipal {
        width: 240px;
    }

    #menu {
        top: 115px;
    }

    #navMobile.active #menu {
        height: calc(100vh - 115px);
    }

    /* btnPadrao */
    .btnPadrao {
        font-size: 0.9rem;
    }

    /* Input Moderno */
    .divInputModerno input {
        font-size: 0.925rem;
        padding: 9px 11px;
    }

    .divInputModerno label {
        font-size: 0.925rem;
    }

    .divInputModerno:focus-within label {
        font-size: 0.8rem !important;
    }

    .divInputModerno input:not(:placeholder-shown)+label {
        font-size: 0.8rem !important;
    }

    .divSelectModerno select {
        font-size: 0.9rem;
        padding: 9px 12px;
    }

    .divSelectModerno select:focus {
        border: 1px solid #059669;
    }

    .divSelectModerno label {
        font-size: 0.925rem;
    }

    .divSelectModerno:focus-within label {
        left: 8px;
        font-size: 0.8rem;
    }

    .divSelectModerno select:not(:placeholder-shown)+label,
    .divSelectModerno select:valid+label {
        left: 8px;
        font-size: 0.8rem;
    }

    :root {
        --swiper-navigation-size: 25px;
        --swiper-pagination-bullet-size: 7px
    }
}

@media(max-width:500px) {

    /* LOGIN */
    #container-login {
        height: 60vh;
    }

    #container-login #esqueceuSenha {
        bottom: 10px;
        font-size: 0.9rem;
    }

    #container-login button {
        font-size: 1.1rem;
    }

    #container-login .divInputModerno label {
        font-size: 1rem;
    }

    #container-login input {
        font-size: 1rem;
    }

    #container-login div.divInputModerno,
    #container-login div.inputSenha {
        border: 1px solid var(--slate-500);
    }

    #container-login div:focus-within {
        border: 1px solid var(--cyan-600);
    }

    /* Cadastrar */
    #containerCadastrar .termos {
        font-size: 0.9rem;
    }

    #containerCadastrar .termos #spanCheckbox:hover {
        background-color: transparent;
    }

    #containerCadastrar #containerInputs {
        margin-top: 1.5rem;
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .passwordContainer label.filled {
        font-size: 0.9rem !important;
    }

    /* HOME */
    .containerProdutos {
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        gap: 10px;
    }
}