.passwordContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

#EyePassword {
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--slate-600);
}

.passwordContainer label.filled {
    top: 0px;
    color: #71717a;
    background-color: #fff;
    padding: 0 2px;
    font-size: 0.9rem !important;
    left: 8px;
    line-height: 0.7;
}