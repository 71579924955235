#containerCadastrar {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-width: 700px;
}

#containerCadastrar form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
}

#containerCadastrar #containerInputs {
    display: grid;
    margin-top: 1.8rem;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

#containerCadastrar .termos {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    font-size: 1.1rem;
}

#containerCadastrar .termos #spanCheckbox {
    padding: 8px;
    display: flex;
    border-radius: 50%; 
    position: relative;
    right: 5px;
    transition: 0.4s;
}

#containerCadastrar .termos #spanCheckbox:hover {
    background-color: #0379ff63;
}

#containerCadastrar .termos input[type="checkbox"] {
    min-width: 20px;
    min-height: 20px;
}

#containerCadastrar .termos label {
    color: var(--slate-800);
}

#containerCadastrar .termos label span {
    color: var(--teal-600);
    text-decoration: underline;
    cursor: pointer;
}

#containerCadastrar form button {
    width: 50%;
}

#containerCadastrar #voltar-login {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 6px;
    font-size: 1.1rem;
}

#containerCadastrar #voltar-login p {
    color: var(--slate-800);
}

#containerCadastrar #voltar-login a {
    color: var(--cyan-600);
    font-weight: bold;
}

.btnCarregamento {
    margin-top: 1.5rem;
}