.containerMainPaginaProdutos {
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 2rem;
}

.containerMainPaginaProdutos .slider {
    margin: 0 auto;
}

.containerMainPaginaProdutos .slider img {
    width: 100%;
}

.containerProdutos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.containerProdutos a {
    text-decoration: none;
    transition: .3s;
}

.containerProdutos a:hover {
    scale: 1.03;
}

.containerProdutos .boxProduto {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(40, 41, 61, 0.10) 0px 0px 2px, rgba(96, 97, 112, 0.16) 0px 0.5px 2px;
    padding: 12px;
    background-color: #fff;
    border-radius: 3px;
    position: relative;
}

.containerProdutos .boxProduto img {
    display: block;
    margin: 0 auto;
    width: 100%;
}

.containerProdutos .boxProduto p:nth-child(3) {
    min-height: 4.2rem;
    max-height: 4.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.4rem;
}

.containerProdutos .boxProduto p:nth-child(4) {
    color: var(--teal-600);
    font-weight: bold;
}

.containerProdutos .boxProduto p:nth-child(5) {
    color: var(--slate-800);
    font-weight: 500;
}

.containerProdutos .boxProduto p:nth-child(5) span {
    color: var(--cyan-600);
}

.containerProdutos .boxProduto button:last-child {
    border: none;
    background-color: var(--teal-700);
    padding: 7px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-weight: bold;
}