@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Inter;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--slate-100);
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    width: 100%;
}

:root {
    --cyan-50: #ecfeff;
    --cyan-100: #cffafe;
    --cyan-200: #a5f3fc;
    --cyan-300: #67e8f9;
    --cyan-400: #22d3ee;
    --cyan-500: #06b6d4;
    --cyan-600: #0891b2;
    --cyan-700: #0e7490;
    --cyan-800: #155e75;
    --cyan-900: #164e63;
    --cyan-950: #072c3b;

    --slate-50: #f8fafc;
    --slate-100: #f1f5f9;
    --slate-200: #e2e8f0;
    --slate-300: #cbd5e1;
    --slate-400: #94a3b8;
    --slate-500: #64748b;
    --slate-600: #475569;
    --slate-700: #334155;
    --slate-800: #1e293b;
    --slate-900: #0f172a;
    --slate-950: #020617;

    --teal-50: #ecfdf5;
    --teal-100: #d1fae5;
    --teal-200: #a7f3d0;
    --teal-300: #6ee7b7;
    --teal-400: #34d399;
    --teal-500: #10b981;
    --teal-600: #059669;
    --teal-700: #047857;
    --teal-800: #065f46;
    --teal-900: #064e3b;
    --teal-950: #022c22;
}

.containerMainApp {
    margin-top: 8.4rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bsPadrao {
    box-shadow: rgba(40, 41, 61, 0.10) 0px 0px 2px, rgba(96, 97, 112, 0.16) 0px 0.5px 2px;
}

li {
    list-style: none;
}

.divInputModerno {
    position: relative;
}

.divInputModerno input,
.divInputModerno textarea {
    border-radius: 6px;
    font-size: 1rem;
    padding: 10px 12px;
    border: 1px solid #71717a;
    background-color: transparent;
    outline: 0;
    transition: 0.2s;
    width: 100%;
}

.divInputModerno input::placeholder,
.divInputModerno textarea::placeholder {
    opacity: 0;
    transition: opacity 0.2s ease;
    color: #71717a;
}

.divInputModerno label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 1rem;
    pointer-events: none;
    transition: 0.2s;
    color: #71717a;
}

.textarea-label-moderno {
    position: absolute;
    top: 1.4rem !important;
    left: 10px;
    transform: translateY(-50%);
    font-size: 1rem;
    pointer-events: none;
    transition: 0.2s;
    color: #71717a;
}

.divInputModerno:focus-within label {
    top: 0px !important;
    left: 8px;
    font-size: 0.85rem;
    background-color: #fff;
    padding: 0 2px;
    color: #059669 !important;
}

.divInputModerno input:focus,
.divInputModerno textarea:focus {
    border: 1px solid #059669;
}

.divInputModerno input:focus::placeholder,
.divInputModerno textarea:focus::placeholder {
    opacity: 1;
}

.divInputModerno input:not(:placeholder-shown)+label,
.divInputModerno textarea:not(:placeholder-shown)+label {
    top: 0px !important;
    color: #71717a;
    left: 8px;
    font-size: 0.85rem;
    background-color: #fff;
    padding: 0 2px;
    /* line-height: 0.7; */
}

.btnPadrao {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 8px;
    font-size: 1.1rem;
    color: var(--teal-50);
    background-color: var(--teal-600);
    transition: 0.2s;
    font-weight: 500;
}

.btnPadrao:hover {
    background-color: var(--teal-700);
}

.containerPadrao {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 1300px;
    min-height: 50vh;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.swiper-button-prev, .swiper-button-next {
    color: #0891b2;
}