.dropdownMenu {
    display: block;
    position: absolute;
    background-color: var(--slate-50);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
    z-index: 2;
    top: 40px;
    width: 181px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.dropdownMenu li {
    position: relative;
}

.dropdownMenu li a {
    padding: 8px;
    color: var(--cyan-800);
    transition: .2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
}

.dropdownMenu li svg {
    color: var(--cyan-800);
}

.dropdownMenu li a:hover {
    background-color: var(--slate-300);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
    display: none;
}

.overlay.active {
    display: block;
}

.dropdownMenu ul {
    background-color: var(--slate-300);
    width: 180px;
    position: absolute;
    left: 181px;
    top: 0px;
    border-bottom-right-radius: 6px;
    display: none;
    height: 320px;
}

.dropdownMenu ul li a {
    color: var(--cyan-700);
}

.dropdownMenu ul li a:hover {
    background-color: var(--slate-50);
}

.dropdownMenu .listaPerife {
    top: -40px;
    height: 200px;
}

.dropdownMenu .listaRedes {
    top: -80px;
    height: 200px;
}

.dropdownMenu .listaComputadores {
    top: -120px;
    height: 200px;
}

#liHardware:hover .listaHardware {
    display: block;
}

#liPerife:hover .listaPerife {
    display: block;
}

#liComputadores:hover .listaComputadores {
    display: block;
}

#liRedes:hover .listaRedes {
    display: block;
}