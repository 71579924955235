.containerMainProduto {
    width: 100%;
    margin: 0 auto;
    margin-top: 2rem;
    background-color: #fff;
    max-width: 1400px;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: rgba(40, 41, 61, 0.10) 0px 0px 2px, rgba(96, 97, 112, 0.16) 0px 0.5px 2px;
    position: relative;
}

.containerMainProduto .containerProdutoInfo {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 1rem;
}

.containerProdutoInfo .containerImgsProduto img {
    width: 65px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.containerProdutoInfo .containerDireitaProduto {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 14px;
}

.containerDireitaProduto .marcaProduto {
    display: flex;
    column-gap: 10px;
}

.containerDireitaProduto .marcaProduto img {
    width: 70px;
}

.containerDireitaProduto #disponi {
    color: green;
    font-weight: bold;
}

.containerDireitaProduto .containerPagVista {
    display: flex;
    column-gap: 10px;
}

.containerDireitaProduto .containerPagVista svg {
    width: 32px;
    height: 32px;
}

.containerDireitaProduto .containerPagPrazo {
    display: flex;
    column-gap: 10px;
}

.containerDireitaProduto .containerPagPrazo svg {
    width: 32px;
    height: 32px;
}

.containerDireitaProduto #btnModalPagamento {
    display: flex;
    border: none;
    background-color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.containerDireitaProduto .btnsComprarAddCart {
    display: flex;
    gap: 10px;
}

.containerDireitaProduto .btnsComprarAddCart button {
    border: none;
    background-color: var(--cyan-600);
    padding: 8px 30px;
    border-radius: 5px;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    color: var(--cyan-50);
    column-gap: 10px;
    margin-top: 10px;
    cursor: pointer;
    transition: .3s;
}

.containerDireitaProduto .btnsComprarAddCart button:hover {
    background-color: var(--cyan-700);
}

.btnsComprarDisable {
    cursor: not-allowed;
}

.btnsComprarDisable button {
    background-color: gray !important;
    color: #fff !important;
    pointer-events: none !important;
}

.btnComprarDisable button:hover {
    background-color: rgb(104, 104, 104) !important;
}

.containerDireitaProduto #btnAddCart {
    font-size: 1.5rem;
    padding: 10px 20px;
}

.containerDireitaProduto .containerFrete #btnFrete {
    border: none;
    background-color: var(--cyan-600);
    padding: 7px;
    border-radius: 5px;
    font-size: 1.2rem;
    color: var(--cyan-50);
    cursor: pointer;
    transition: .3s;
}