.header {
    display: flex;
    flex-direction: column;
    background-color: var(--cyan-950);
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 5;
}

.containerHeaderItens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    height: 6rem;
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
}

.containerHeaderItens a {
    text-decoration: none;
}

.containerHeaderItens .containerLoginReg .contaSair {
    display: flex;
    gap: 5px;
    align-items: center;
}

.containerHeaderItens .containerLoginReg button {
    background-color: transparent;
    border: none;
    color: var(--cyan-50);
    margin-top: 4px;
    cursor: pointer;
    transition: 0.2s;
    font-size: 0.9rem;
}

.containerHeaderItens .containerLoginReg button:hover {
    color: var(--cyan-300);
}

.containerHeaderItens .containerLoginReg .contaSair span {
    font-size: 0.9rem;
}

.containerHeaderItens .containerLoginReg a {
    color: var(--cyan-500);
    text-decoration: none;
    font-weight: bold;
    line-height: 1.4;
}

.containerHeaderItens .user-icon {
    margin-right: 10px;
}

.containerHeaderItens .containerFavCart {
    display: flex;
    gap: 10px;
}

.containerHeaderItens .containerFavCart svg {
    width: 25px;
    height: 25px;
    fill: white;
}

.containerHeaderItens .containerFavCart .carrinho {
    display: flex;
}

.containerHeaderItens .containerFavCart span {
    position: relative;
    bottom: 8px;
    right: 3px;
    background-color: var(--cyan-600);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: 20px;
    height: 20px;
}