.mainCarrinho {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3rem;
    gap: 1.5rem;
}

.mainCarrinho .containerPrincipal {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
}

.mainCarrinho .containerPrincipal .limparCarrinho {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #fff;
    margin-left: auto;
    padding: 8px;
    border-radius: 6px;
    box-shadow: rgba(40, 41, 61, 0.10) 0px 0px 2px, rgba(96, 97, 112, 0.16) 0px 0.5px 2px;
    cursor: pointer;
}

.mainCarrinho .containerPrincipal .limparCarrinho svg {
    color: #be0707;
}

.mainCarrinho .containerPrincipal .containerFrete {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    box-shadow: rgba(40, 41, 61, 0.10) 0px 0px 2px, rgba(96, 97, 112, 0.16) 0px 0.5px 2px;
}

.mainCarrinho .containerPrincipal .inputBtnFrete {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.mainCarrinho .containerPrincipal .valorPrazo {
    background-color: #fff;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    white-space: nowrap;
}