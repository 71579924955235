footer {
    background-color: var(--cyan-950);
}

.containerFooter {
    display: block;
    height: auto;
    font-size: 0.9rem;
}

.containerFooter section.caixas-informacoes {
    width: 80%;
    display: flex;
    max-width: 1300px;
    justify-content: space-between;
    margin: 0 auto;
    column-gap: 1rem;
}

.containerFooter div.box {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.containerFooter div.box h5 {
    margin-bottom: 0.9rem;
    color: var(--cyan-50);
    font-weight: bold;
}

.containerFooter div.box ul li {
    list-style: none;
    margin-bottom: 8px;
    color: var(--cyan-50);
}

.containerFooter div.pagamentos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.8vw;
    width: 100%;
}

.containerFooter div.pagamentos img {
    width: 3.6rem;
    background-color: #fff;
    padding: 10px 12px;
    border-radius: 4px;
    height: 34px;
}

.containerFooter div.pagamentos img:nth-child(5) {
    padding: 0px 12px;
}

.containerFooter div.redes-sociais {
    display: flex;
}

.containerFooter div.redes-sociais svg {
    width: 18px;
    height: auto;
    fill: var(--cyan-50);
}

.containerFooter div.redes-sociais p {
    margin-left: 5px;
    color: var(--cyan-50);
    white-space: nowrap;
}

.containerFooter div.redes-sociais:not(:last-child) {
    margin-bottom: 8px;
}

.rodape {
    width: 80%;
    margin: 0 auto;
    background-color: var(--cyan-950);
    color: var(--cyan-50);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

footer .rodape hr {
    display: block;
    border-color: var(--cyan-50);
    margin-bottom: 1rem;
    width: 100%;
    max-width: 1300px;
}