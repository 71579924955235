.navBar {
    display: flex;
    justify-content: center;
    background-color: var(--cyan-900);
    box-shadow: rgba(40, 41, 61, 0.4) 0px 1px 2px, rgba(96, 97, 112, 0.4) 0px 1px 2px;
}

.navBar .lista {
    display: flex;
    width: 80%;
    max-width: 1300px;
    justify-content: space-between;
    align-items: center;
}

.navBar .lista .bordaPadrao {
    text-decoration: none;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    padding: 8px 5px;
    transition: 0.3s;
    border-left: 1px solid var(--cyan-700);
}

.navBar .lista li {
    list-style: none;
    font-size: 1rem;
    color: var(--slate-50);
    font-weight: bold;
    white-space: nowrap;
    position: relative;
}

.navBar .lista #liDPT {
    padding: 8px 2px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.navBar a:hover {
    background-color: var(--cyan-700);
}

.navBar li #setaParaBaixo {
    color: var(--cyan-50);
    width: 15px;
    transition: 0.3s;
    margin-right: 10px;
}

.navBar li:hover #setaParaBaixo {
    transform: rotate(180deg);
}




