#container-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-width: 500px;
    min-width: 300px;
}

#container-login form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 10px;
    gap: 1rem;
    position: relative;
}

#container-login h1 {
    color: var(--cyan-600);
    font-weight: bold;
    font-size: 2rem;
}

#container-login div.divInputModerno,
#container-login div.inputSenha {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--slate-600);
    border-radius: 6px;
    transition: .2s;
    gap: 5px;
}

#container-login input {
    border: none;
    font-size: 1.1rem;
}

#container-login .divInputModerno label {
    font-size: 1.1rem;
}

#container-login .divInputModerno:focus-within label {
    font-size: 0.9rem !important;
}

#container-login .divInputModerno input:not(:placeholder-shown)+label{
    font-size: 0.9rem ;
}

#container-login .divInputModerno:focus-within label {
    color: #0891b2 !important;
}

#container-login div:focus-within {
    outline: 1;
    border: 1px solid var(--cyan-600);
    box-shadow: 0px 0px 0px 1px var(--cyan-600);
}

#container-login div svg:nth-child(1) {
    position: relative;
    left: 7px;
    font-size: 1.8rem;
    color: var(--slate-600);
}

#container-login button {
    margin: 0 auto;
    font-size: 1.2rem;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    color: aliceblue;
    background-color: var(--cyan-700);
    border: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}

#container-login #a-btn-criar {
    width: 100%;
}

#container-login #a-btn-criar button {
    background-color: var(--teal-700);
}

#container-login #a-btn-criar {
    text-decoration: none;
}