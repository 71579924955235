.divSelectModerno {
    position: relative;
}

.divSelectModerno select {
    appearance: none;
    border-radius: 6px;
    font-size: 1rem;
    padding: 10px 12px;
    border: 1px solid #71717a;
    background-color: transparent;
    outline: 0;
    transition: 0.2s;
    width: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 24 24'%3E%3Cpath fill='%2371717a' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 25px 25px;
}

.divSelectModerno select:focus {
    border: 1px solid #059669;
}

.divSelectModerno label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 1rem;
    pointer-events: none;
    transition: 0.2s;
    color: #71717a;
}

.divSelectModerno:focus-within label {
    top: 0px !important;
    left: 8px;
    font-size: 0.85rem;
    background-color: #fff;
    padding: 0 2px;
    color: #059669 !important;
}

.divSelectModerno select:not(:placeholder-shown)+label,
.divSelectModerno select:valid+label {
    top: 0px !important;
    left: 8px;
    font-size: 0.85rem;
    background-color: #fff;
    padding: 0 2px;
}